import { useStaticQuery, graphql } from "gatsby"

const useSocialData = id => {
  const data = useStaticQuery(graphql`
    query socialQuery {
      socials: allSocialJson(filter: { data: { rating: { gte: 4 } } }) {
        nodes {
          data {
            code
            platform
            rating
            platformId
            totalReviews
            url
          }
        }
      }
    }
  `)

  const allSocials = data.socials.nodes

  let social = null

  if (!id) return { social }

  const find = allSocials.find(s => s.data.platformId === id)

  if (find) {
    social = find.data
  }
  return { social }
}

export default useSocialData
